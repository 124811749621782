// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconRFP(props) {
  var __color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "RFP";
  var color = __color !== undefined ? __color : "White";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M3.667 0a.889.889 0 01.889.889v1.93a2.667 2.667 0 010 5.029v7.263a.889.889 0 11-1.778 0V7.85a2.668 2.668 0 010-5.031V.888A.889.889 0 013.667 0zm8.889 0a.889.889 0 01.888.889v7.263a2.668 2.668 0 010 5.03v1.93a.889.889 0 11-1.777 0v-1.93a2.667 2.667 0 010-5.03V.888A.889.889 0 0112.556 0z",
                    fill: Icon.mapColor(color)
                  })
            });
}

var make = IconRFP;

export {
  make ,
}
/* Icon Not a pure module */
