// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconSolidCart(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "solidCart";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("path", {
                      d: "M-3.556-7.53h23.111c.708 0 1.386.298 1.886.828s.781 1.247.781 1.996v24.47a2.91 2.91 0 01-.78 1.997c-.5.53-1.18.827-1.887.827h-23.11a2.594 2.594 0 01-1.886-.827 2.91 2.91 0 01-.781-1.996v-24.47c0-.75.28-1.468.78-1.997.5-.53 1.18-.827 1.886-.827z",
                      fill: "transparent"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M4.352 12.235c.962 0 1.741.843 1.741 1.883S5.313 16 4.352 16c-.962 0-1.74-.842-1.74-1.882s.778-1.883 1.74-1.883zm6.964 0c.96 0 1.74.843 1.74 1.883S12.276 16 11.316 16c-.962 0-1.742-.842-1.742-1.882s.78-1.883 1.742-1.883zM2.612 0l.2 2.824H15.13c.48 0 .87.421.87.94 0 .147-.031.29-.092.421l-2.611 5.647a.904.904 0 01-.319.379.834.834 0 01-.46.142H3.38a.85.85 0 01-.58-.239.948.948 0 01-.284-.586L1.74 2.824 1.552.94H0V0h2.612z",
                      fill: Icon.mapColor(props.color)
                    })
              ]
            });
}

var make = IconSolidCart;

export {
  make ,
}
/* Icon Not a pure module */
